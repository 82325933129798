














import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "icon" })
export default class VIcon extends Vue {
  @Prop({ default: false }) spin?: boolean;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: true }) colorReset?: boolean;

  getIcon() {
    return this.$slots.default && this.$slots.default.length
      ? this.$slots.default[0].text
      : null;
  }

  getSvg() {
    return this.getIcon()
      ? require(`!svg-inline-loader!@/assets/icons/${this.getIcon()}.svg`)
      : null;
  }

  @Prop({ default: "md" }) readonly size!:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "xxl"
    | "xxxl";

  get sizeClass() {
    return `icon-${this.size}`;
  }

  onClick() {
    if (!this.disabled) {
      this.$emit("click");
    }
  }
}
