

import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

Component.registerHooks(['beforeRouteEnter']);

@Component
export default class AppRouter extends Vue {
  beforeRouteEnter(to: Route, _from: Route, next: (...params: any[]) => void) {
    switch (to.query.action) {
      case 'complete_sign_up':
        next({ name: 'confirmation', query: to.query })
        break;
      case 'reset_password':
        next({ name: 'reset_password', query: to.query })
        break;
      default:
        next('/');
    }
  }

  render() {
    return null;
  }
}
