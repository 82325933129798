import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import AppRouter from "../views/AppRouter.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/app",
    name: 'AppRouter',
    component: AppRouter,
  },
  {
    path: "/password",
    name: "home_password",
    component: () => import(/* webpackChunkName: "password" */ "../views/Password/Home.vue"),
    children: [
      {
        path: "success",
        name: "success",
        component: () =>
          import(/* webpackChunkName: "password" */ "../views/Password/PasswordConfirmationSuccess.vue")
      },
      { path: 'confirmation', component: () => import(/* webpackChunkName: "password" */'@/views/Password/Confirmation.vue'), name: 'confirmation' },
      { path: 'forgot', component: () => import(/* webpackChunkName: "password" */'@/views/Password/Forgot.vue'), name: 'forgot_password' },
      { path: 'reset', component: () => import(/* webpackChunkName: "password" */'@/views/Password/Reset.vue'), name: 'reset_password' }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
