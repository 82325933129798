import Vue from "vue";
import Vuex from "vuex";
import http from "./http";
import auth from "./auth";

Vue.use(Vuex);
import VuexPersist from "vuex-persist";

const vuexPersist = new VuexPersist({
  key: "peimar-monitoring",
  storage: window.localStorage,
  modules: ["auth"]
});

export default new Vuex.Store({
  modules: { http, auth },
  plugins: [vuexPersist.plugin]
});
