export const BASE_API_URL: string =
  process.env.BASE_API_URL ?? "http://peimar-monitoring-api.test/v1/";

export const MM = 0.1; // centimeter is the base unit
export const CM = 1; // centimeter is the base unit
export const METER = 100;
export const KM = 1000 * METER;

export const BASE_SIZE = 20;
export const GRID_SIZE: number = 1000 * METER;


export enum KeyCodes { Esc = 27, Delete = 46, BackSpace = 8 }

/**
 * Due to limitations of the OpenGL Core Profile with the WebGL renderer on
 * most platforms linewidth will always be 1 regardless of the set value.
 * https://threejs.org/docs/#api/en/materials/LineBasicMaterial.linewidth
 */
export const LINE_WIDTH = 1;
