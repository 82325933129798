import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/app.scss";
import Toasted from "vue-toasted";
import ModelParserMixin from "@/mixins/ModelParser";
import RouteHelpersMixin from "@/mixins/RouteHelpers";
import RequestsMixin from "@/mixins/http/RequestsMixin";
import DisplayErrors from "@/components/DisplayErrors.vue";
import VIcon from "@/components/VIcon.vue";
import VContentLoading from "@/components/VContentLoading.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "@/initializers";

declare module "vue/types/vue" {
  interface Vue {
    $parseModel(list: any | any[]): any;
    $getModelRoutes(name: string): any;
    $httpGet(
      url: string,
      tag: string,
      fields?: string[] | string
    ): Promise<any>;
    $httpPost(url: string, tag: string, data: any): Promise<any>;
    $httpPut(url: string, tag: string, data: any): Promise<any>;
    $httpDelete(url: string, tag: string, data: any): Promise<any>;
    $isRouteActive(route: string): boolean;
    $areRouteActive(routes: string[]): boolean;
  }
}

Vue.config.productionTip = false;
Vue.use(Toasted);
Vue.component("v-icon", VIcon);
Vue.component("display-errors", DisplayErrors);
Vue.component("v-loading", VContentLoading);
Vue.component("fa-icon", FontAwesomeIcon);

Vue.mixin(ModelParserMixin);
Vue.mixin(RouteHelpersMixin);
Vue.mixin(RequestsMixin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
