






import { Component, Prop, Vue } from "vue-property-decorator";
import { httpStore } from "@/store/typed";

@Component
export default class DisplayErrors extends Vue {
  @Prop({ required: true }) readonly tag!: string;

  get errors() {
    return (
      httpStore.status[this.tag] && httpStore.status[this.tag].statusCode === 422 &&
      httpStore.status[this.tag].errors
    );
  }
}
