import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RouteHelpers extends Vue {
  $isRouteActive(route: string) : boolean {
    return !!this.$route.matched.find((e : any) => e.name === route);
  }
  $areRouteActive(routes: string[]) : boolean {
    return !!this.$route.matched.some((e: any) => routes.includes(e.name) );
  }
}
