














import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class VContentLoading extends Vue {
  @Prop({ default: "3x" })
  readonly notchSize!: string;
  @Prop()
  readonly message?: string;
}
