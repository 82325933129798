import User from '../models/User';
import axios from 'axios';
import { BASE_API_URL } from '../utils/Constants';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  stateFactory: true,
  namespaced: false,
  name: 'auth'
})
export default class Auth extends VuexModule {
  _token: string | undefined = undefined;

  get accessToken() {
    return this._token;
  }

  @Mutation
  authSuccess(token: string) {
    this._token = token;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  @Mutation
  clearAuth() {
    this._token = undefined;
    delete axios.defaults.headers.common['Authorization'];
  }

  @Action
  login(user: { email: string; password: string }): Promise<User | undefined> {
    const { commit, dispatch } = this.context;
    commit('setCurrentUser', undefined);
    commit('clearAuth');

    // Routes.user_session_path({ format: 'json' })

    return axios({ url: BASE_API_URL + 'login', data: { user }, method: 'POST' })
      .then(
        resp => {
          const [_, token] = (resp.headers.authorization || ' ').split(' ');
          return dispatch('authenticate', token);
        },
        err => Promise.reject(undefined));
  }

  @Action
  logout() {
    const { commit } = this.context;
    commit('setCurrentUser', undefined);
    commit('clearAuth');
  }

  @Action({ rawError: true })
  async authenticate(token?: string): Promise<User | undefined> {
    const { commit, dispatch, rootGetters, getters } = this.context;

    token = token || getters.accessToken;

    if (!token) {
      throw new Error('Missing token');
    }

    if (rootGetters.currentUser instanceof User) {
      return rootGetters.currentUser;
    }
    try {
      const user = await dispatch('loadCurrentUser', { headers: { Authorization: `Bearer ${token}` } });
      commit('authSuccess', token);
      return user;
    } catch (e) {
      commit('clearAuth');
      throw e;
    }
  }
}
