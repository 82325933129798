import Vue from 'vue';
import VTooltip from 'v-tooltip';

const options = {
  defaultOffset: 5,
  popover: {
    defaultTrigger: 'hover focus'
  }
};

Vue.use(VTooltip, options);
