import Model from "./Model";
import IUser from "./interfaces/IUser";

export class UserPayload {
  id?: number | string | null = null;
  email?: string | null = null;

  fromData(data: IUser) {
    this.id = data.id;
    this.email = data.email;
  }
}

export default class User extends Model implements IUser {
  static type = "users";

  email!: string;

  static fromData(data: any) {
    let user = new User(undefined);
  }
}
