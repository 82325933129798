import axios from 'axios';
import Vue from 'vue';
import Router from '@/router/index';

const token = document.head.querySelector('meta[name="csrf-token"]');

axios.interceptors.response.use(
  (r) => r, (error) => {
    if (error?.response?.status === 422 || error?.response?.status === 404) {
      return Promise.reject(error);
    }

    const message = error.response?.data && (error.response.data.errors && error.response.data.errors.message || error.response.data.message) || error.message;
    Vue.toasted.show(message, {
      position: 'bottom-right', type: 'error', icon: 'warning', duration: 5000, keepOnHover: true,
      action: {
        text: 'Chiudi',
        onClick(e: any, toastObject: { goAway: (arg0: number) => void }) {
          toastObject.goAway(0);
        }
      }
    } as any);
    if (error.response.status === 401) {
      if (Router.currentRoute.name !== 'login') {
        Router.push('/login');
      }
    }
    return Promise.reject(error);
  }
);
